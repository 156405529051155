import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate, Routes, Route, Navigate } from "react-router-dom"
import {
    Home,
    BarChart,
    FileText,
    PanelLeft,
    Activity,
    LogOut,
    Mail
} from "lucide-react"
import { Button } from "./ui/button"
import { Sheet, SheetContent, SheetTrigger } from "./ui/sheet"
import {
    Tooltip,
    TooltipContent,
    TooltipTrigger,
} from "./ui/tooltip"
import SEO from './sections-admin/SEO'
import Logs from './sections-admin/Logs'
import PortfolioEditor from './sections-admin/Portfolio'



const AdminDashboard = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [csrfToken, setCsrfToken] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        checkAdminAuthentication();
    }, []);

    const checkAdminAuthentication = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/check-admin-auth`, {
                method: 'GET',
                credentials: 'include',
            });
            if (response.ok) {
                const data = await response.json();
                setIsAuthenticated(data.authenticated);
                if (data.authenticated) {
                    fetchCsrfToken();
                }
            } else {
                setIsAuthenticated(false);
            }
        } catch (error) {
            console.error('Admin authentication check failed:', error);
            setIsAuthenticated(false);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchCsrfToken = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/csrf-token`, {
                method: 'GET',
                credentials: 'include',
            });
            if (response.ok) {
                const data = await response.json();
                setCsrfToken(data.csrfToken);
            } else {
                throw new Error('Failed to fetch CSRF token');
            }
        } catch (error) {
            console.error('Error fetching CSRF token:', error);
        }
    };

    const handleLogout = async () => {
        try {
            await fetch(`${process.env.REACT_APP_API_URL}/api/admin/logout`, {
                method: 'POST',
                headers: {
                    'X-CSRF-Token': csrfToken,
                },
                credentials: 'include',
            });
            setIsAuthenticated(false);
            navigate('/awh', { replace: true });
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    const NavLink = ({ to, icon: Icon, label, onClick }) => (
        <Tooltip>
            <TooltipTrigger asChild>
                <Link
                    to={to}
                    onClick={onClick}
                    className={`flex h-9 w-9 items-center justify-center rounded-lg transition-colors hover:text-foreground md:h-8 md:w-8 ${location.pathname === to ? "bg-accent text-accent-foreground" : "text-muted-foreground"
                        }`}
                >
                    <Icon className="h-5 w-5" />
                    <span className="sr-only">{label}</span>
                </Link>
            </TooltipTrigger>
            <TooltipContent side="right">{label}</TooltipContent>
        </Tooltip>
    );

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        return <Navigate to="/awh" replace />;
    }

    return (
        <div className="flex min-h-screen w-full flex-col bg-muted/40">
            <aside className="fixed inset-y-0 left-0 z-10 hidden w-14 flex-col border-r bg-background sm:flex">
                <nav className="flex flex-col items-center gap-4 px-2 py-4">
                    <NavLink to="/awh/dashboard" icon={Home} label="Dashboard" />
                    <NavLink to="/awh/dashboard/seo" icon={BarChart} label="SEO" />
                    <NavLink to="/awh/dashboard/logs" icon={FileText} label="Logs" />
                    <NavLink to="/awh/dashboard/portfolio" icon={Mail} label="Portfolio" />
                </nav>
                <nav className="mt-auto flex flex-col items-center gap-4 px-2 py-4">
                    <NavLink to="#" icon={LogOut} label="Logout" onClick={handleLogout} />
                </nav>
            </aside>
            <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-14">
                <header className="sticky top-0 z-30 flex h-14 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
                    <Sheet>
                        <SheetTrigger asChild>
                            <Button size="icon" variant="outline" className="sm:hidden">
                                <PanelLeft className="h-5 w-5" />
                                <span className="sr-only">Toggle Menu</span>
                            </Button>
                        </SheetTrigger>
                        <SheetContent side="left" className="sm:max-w-xs">
                            <nav className="flex flex-col space-y-4">
                                <Link to="/awh/dashboard" className="flex items-center space-x-2">
                                    <Home className="h-5 w-5" />
                                    <span>Dashboard</span>
                                </Link>
                                <Link to="/awh/dashboard/seo" className="flex items-center space-x-2">
                                    <BarChart className="h-5 w-5" />
                                    <span>SEO</span>
                                </Link>
                                <Link to="/awh/dashboard/logs" className="flex items-center space-x-2">
                                    <FileText className="h-5 w-5" />
                                    <span>Logs</span>
                                </Link>
                                <Link to="/awh/dashboard/portfolio" className="flex items-center space-x-2">
                                    <FileText className="h-5 w-5" />
                                    <span>Portfolio</span>
                                </Link>
                         
                            </nav>
                        </SheetContent>
                    </Sheet>
                </header>
                <main className="flex-1 p-4 sm:px-6 sm:py-0">
                    <Routes>
                        <Route path="/" element={<DashboardHome />} />
                        <Route path="seo" element={<SEO csrfToken={csrfToken} />} />
                        <Route path="logs" element={<Logs />} />
                        <Route path="/portfolio" element={<PortfolioEditor />} />
                    </Routes>
                </main>
            </div>
        </div>
    )
}

const DashboardHome = () => (
    <div className="w-full max-w-6xl mx-auto space-y-8">
        <h1 className="text-2xl font-bold">Welcome to the Admin Dashboard</h1>
    </div>
);

export default AdminDashboard;