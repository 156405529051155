import React from 'react';
import { Link } from 'react-router-dom';
import { BookOpen, FileText } from 'lucide-react';

const Nav = () => {
    return (
        <nav className="flex justify-center items-center space-x-4 w-full">
            <Link
                to="/blog"
                className="bg-white text-black py-2 px-6 rounded-full text-sm hover:bg-opacity-90 transition duration-300 shadow-sm font-bold flex items-center space-x-2"
            >
                <FileText size={16} />
                <span>Blog</span>
            </Link>
            <Link
                to="/"
                className="bg-white text-black py-2 px-6 rounded-full text-sm hover:bg-opacity-90 transition duration-300 shadow-sm font-bold flex items-center space-x-2"
            >
                <BookOpen size={16} />
                <span>Free Resources</span>
            </Link>
        </nav>
    );
};

export default Nav;