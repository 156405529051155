import React, { useState, useEffect } from 'react';

const Footer = () => {
    const [portfolioItems, setPortfolioItems] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPortfolioItems = async () => {
            try {
                console.log('Fetching portfolio items...');
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/public/portfolio-items`);
                console.log('Response status:', response.status);
                if (!response.ok) {
                    throw new Error(`Failed to fetch portfolio items: ${response.status}`);
                }
                const data = await response.json();
                console.log('Fetched data:', data);
                setPortfolioItems(data);
            } catch (error) {
                console.error('Error fetching portfolio items:', error);
                setError(error.message);
            }
        };
        fetchPortfolioItems();
    }, []);

    return (
        <footer className="w-full bg-black bg-opacity-50 mt-auto py-4 px- text-center">
            <div className="font-mono text-xs mb-2">
                <span className="text-gray-400">{'<'}</span>
                <span className="text-yellow-400">Copyright</span>
                <span className="text-gray-400">{'="'}</span>
                <span className="text-white">&copy; 2024 Frontier Codes. All rights reserved.</span>
                <span className="text-gray-400">{'">'}</span>
            </div>
            <div className="font-mono text-xs">
                <span className="text-gray-400">{'<'}</span>
                <span className="text-yellow-400">Portfolio</span>
                <span className="text-gray-400">{'="'}</span>
                <span className="text-white">
                    {error ? (
                        <span className="text-red-500">Error: {error}</span>
                    ) : portfolioItems.length > 0 ? (
                        portfolioItems.map((item, index) => (
                            <React.Fragment key={item.name}>
                                <a
                                    href={item.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="hover:underline"
                                >
                                    {item.name}
                                </a>
                                {index < portfolioItems.length - 1 && ', '}
                            </React.Fragment>
                        ))
                    ) : (
                        <span>No portfolio items available</span>
                    )}
                </span>
                <span className="text-gray-400">{'">'}</span>
            </div>
        </footer>
    );
};

export default Footer;