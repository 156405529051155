import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Calculator } from 'lucide-react';

const blurIn = keyframes`
  0% { filter: blur(10px); opacity: 0; }
  100% { filter: blur(0); opacity: 1; }
`;

const AnimatedText = styled.div`
  animation: ${blurIn} 1.5s ease-out;
`;

const AnimatedButton = styled.button`
  animation: ${blurIn} 1.5s ease-out;
`;

// Function to combine email parts
const combineEmail = () => {
    const username = 'quote';
    const domain = 'frontier.codes';
    return `${username}@${domain}`;
};

// Function to handle email click
const handleEmailClick = () => {
    const email = combineEmail();
    window.location.href = `mailto:${email}?subject=Quote Request`;
};

const HeroContent = () => (
    <div className="text-center bg-black py-16 px-4 rounded-2xl mt-8 border border-gray-600">
        <AnimatedText>
            <h1 className="text-5xl font-extrabold text-white mb-6">
                Faster. Cheaper. Better.
            </h1>
            <p className="text-2xl text-white mb-8 max-w-3xl mx-auto">
                Harnessing cutting-edge AI to transform software development.
                We deliver enterprise-grade solutions with unprecedented speed and cost-efficiency,
                without compromising on quality or innovation.
            </p>
        </AnimatedText>
        <div className="flex justify-center space-x-4">
            <AnimatedButton
                onClick={handleEmailClick}
                className="bg-white text-black py-2 px-6 rounded-full text-sm hover:bg-opacity-90 transition duration-300 shadow-sm font-bold flex items-center space-x-2"
            >
                <Calculator size={16} />
                <span>Get a Quote</span>
            </AnimatedButton>
        </div>
        <AnimatedText>
            <div className="mt-12 flex justify-center space-x-8">
                <div className="text-white">
                    <span className="block text-4xl font-bold ">50%</span>
                    <span className="text-sm">Faster Delivery</span>
                </div>
                <div className="text-white">
                    <span className="block text-4xl font-bold">40%</span>
                    <span className="text-sm">Cost Reduction</span>
                </div>
                <div className="text-white">
                    <span className="block text-4xl font-bold">100%</span>
                    <span className="text-sm">Satisfaction</span>
                </div>
            </div>
        </AnimatedText>
    </div>
);

export default HeroContent;