import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from "../ui/card";
import { Alert, AlertDescription } from "../ui/alert";

const Logs = () => {
    const [logs, setLogs] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        fetchLogs();
    }, []);

    const fetchLogs = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/logs`, {
                method: 'GET',
                credentials: 'include',
            });
            if (!response.ok) {
                throw new Error('Failed to fetch logs');
            }
            const data = await response.text();
            setLogs(data);
        } catch (error) {
            console.error('Error fetching logs:', error);
            setError('Failed to fetch logs. Please try again.');
        }
    };

    return (
        <Card>
            <CardHeader>
                <CardTitle>System Logs</CardTitle>
            </CardHeader>
            <CardContent>
                {error && <Alert variant="destructive" className="mb-4"><AlertDescription>{error}</AlertDescription></Alert>}
                <pre className="whitespace-pre-wrap">{logs}</pre>
            </CardContent>
        </Card>
    );
};

export default Logs;