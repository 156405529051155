import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "../ui/card";
import { Button } from "../ui/button";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Alert, AlertDescription } from "../ui/alert";
import { Switch } from "../ui/switch";
import { Separator } from "../ui/separator";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import { Loader2, ChevronDown, ChevronUp, Plus, Trash2 } from "lucide-react";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../ui/dialog";


const SEO = ({ csrfToken }) => {
    const [error, setError] = useState('');
    const [isAutomaticMode, setIsAutomaticMode] = useState(false);
    const [loadingStates, setLoadingStates] = useState({
        toggleMode: false,
        generateAll: false,
        generateSection: {},
        changeImage: {},
        fetchSections: false,
        createSection: false,
    });
    const [expandedSections, setExpandedSections] = useState({});
    const [sectionPosts, setSectionPosts] = useState({});
    const [sections, setSections] = useState([]);
    const [inputSubject, setInputSubject] = useState('');
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);

    useEffect(() => {
        fetchAutomaticModeStatus();
        fetchSections();
    }, []);

    useEffect(() => {
        let timer;
        if (error) {
            timer = setTimeout(() => {
                setError('');
            }, 4000);
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [error]);

    const fetchAutomaticModeStatus = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/automatic-mode-status`, {
                method: 'GET',
                credentials: 'include',
            });
            if (!response.ok) {
                throw new Error('Failed to fetch automatic mode status');
            }
            const data = await response.json();
            setIsAutomaticMode(data.isAutomaticMode);
        } catch (error) {
            console.error('Error fetching automatic mode status:', error);
        }
    };

    const handleToggleAutomaticMode = async () => {
        setLoadingStates(prev => ({ ...prev, toggleMode: true }));
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/toggle-automatic-mode`, {
                method: 'POST',
                headers: {
                    'X-CSRF-Token': csrfToken,
                },
                credentials: 'include',
            });
            if (!response.ok) {
                throw new Error('Failed to toggle automatic mode');
            }
            const data = await response.json();
            setIsAutomaticMode(data.isAutomaticMode);
        } catch (error) {
            console.error('Error toggling automatic mode:', error);
            setError('Failed to toggle automatic mode. Please try again.');
        } finally {
            setLoadingStates(prev => ({ ...prev, toggleMode: false }));
        }
    };

    const handleGenerateAllSpokeContent = async () => {
        setLoadingStates(prev => ({ ...prev, generateAll: true }));
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/generate-all-spoke-content`, {
                method: 'POST',
                headers: {
                    'X-CSRF-Token': csrfToken,
                },
                credentials: 'include',
            });
            if (!response.ok) {
                throw new Error('Failed to generate all spoke content');
            }
            setError('All spoke content generation completed successfully!');
        } catch (error) {
            console.error('Error generating all spoke content:', error);
            setError('Failed to generate all spoke content. Please try again.');
        } finally {
            setLoadingStates(prev => ({ ...prev, generateAll: false }));
        }
    };

    const handleGenerateSpokeContentForSection = async (sectionId) => {
        setLoadingStates(prev => ({
            ...prev,
            generateSection: { ...prev.generateSection, [sectionId]: true }
        }));
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/generate-spoke-content/${sectionId}`, {
                method: 'POST',
                headers: {
                    'X-CSRF-Token': csrfToken,
                },
                credentials: 'include',
            });
            if (!response.ok) {
                throw new Error(`Failed to generate spoke content for section ${sectionId}`);
            }
            setError(`Spoke content generation completed for section ${sectionId}`);
            await fetchSectionPosts(sectionId);
        } catch (error) {
            console.error(`Error generating spoke content for section ${sectionId}:`, error);
            setError(`Failed to generate spoke content for section ${sectionId}. Please try again.`);
        } finally {
            setLoadingStates(prev => ({
                ...prev,
                generateSection: { ...prev.generateSection, [sectionId]: false }
            }));
        }
    };

    const handleChangeImageForPost = async (sectionId, postId) => {
        const loadingKey = `${sectionId}-${postId}`;
        setLoadingStates(prev => ({
            ...prev,
            changeImage: { ...prev.changeImage, [loadingKey]: true }
        }));
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/change-image/${sectionId}/${postId}`, {
                method: 'POST',
                headers: {
                    'X-CSRF-Token': csrfToken,
                },
                credentials: 'include',
            });
            if (!response.ok) {
                throw new Error(`Failed to change image for post ${postId} in section ${sectionId}`);
            }
            setError(`Image changed successfully for post ${postId} in section ${sectionId}`);
            await fetchSectionPosts(sectionId);
        } catch (error) {
            console.error(`Error changing image for post ${postId} in section ${sectionId}:`, error);
            setError(`Failed to change image for post. Please try again.`);
        } finally {
            setLoadingStates(prev => ({
                ...prev,
                changeImage: { ...prev.changeImage, [loadingKey]: false }
            }));
        }
    };

  

    const toggleSection = async (sectionId) => {
        setExpandedSections(prev => ({
            ...prev,
            [sectionId]: !prev[sectionId]
        }));

        if (!sectionPosts[sectionId]) {
            await fetchSectionPosts(sectionId);
        }
    };

    const fetchSectionPosts = async (sectionId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/section-posts/${sectionId}`, {
                method: 'GET',
                headers: {
                    'X-CSRF-Token': csrfToken,
                },
                credentials: 'include',
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch posts for section ${sectionId}`);
            }
            const data = await response.json();
            setSectionPosts(prev => ({
                ...prev,
                [sectionId]: data.posts.map(post => ({
                    id: post.id,
                    title: post.title || 'Untitled',
                    featuredImage: post.featuredImage || { url: '' }
                }))
            }));
        } catch (error) {
            console.error(`Error fetching posts for section ${sectionId}:`, error);
            setError(`Failed to fetch posts for section ${sectionId}. Please try again.`);
        }
    };

    const fetchSections = async () => {
        setLoadingStates(prev => ({ ...prev, fetchSections: true }));
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/sections`, {
                method: 'GET',
                headers: {
                    'X-CSRF-Token': csrfToken,
                },
                credentials: 'include',
            });
            if (!response.ok) {
                throw new Error('Failed to fetch sections');
            }
            const data = await response.json();
            setSections(data.sections);
        } catch (error) {
            console.error('Error fetching sections:', error);
            setError('Failed to fetch sections. Please try again.');
        } finally {
            setLoadingStates(prev => ({ ...prev, fetchSections: false }));
        }
    };

    const handleCreateSection = async () => {
        if (!inputSubject.trim()) {
            setError('Please enter a subject for the new section.');
            return;
        }

        setLoadingStates(prev => ({ ...prev, createSection: true }));
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/create-section`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': csrfToken,
                },
                body: JSON.stringify({ inputSubject }),
                credentials: 'include',
            });
            if (!response.ok) {
                throw new Error('Failed to create new section');
            }
            const data = await response.json();
            setError('New section created successfully!');
            setInputSubject('');
            fetchSections(); // Refresh the sections list
        } catch (error) {
            console.error('Error creating new section:', error);
            setError('Failed to create new section. Please try again.');
        } finally {
            setLoadingStates(prev => ({ ...prev, createSection: false }));
        }
    };


    const handleDeleteSection = (sectionId) => {
        setDeleteConfirmation({
            type: 'section',
            id: sectionId,
            message: 'Are you sure you want to delete this section? This action cannot be undone.'
        });
    };

    const handleDeletePost = (sectionId, postId) => {
        setDeleteConfirmation({
            type: 'post',
            sectionId,
            postId,
            message: 'Are you sure you want to delete this post? This action cannot be undone.'
        });
    };

    const confirmDelete = async () => {
        if (!deleteConfirmation) return;

        try {
            let response;
            if (deleteConfirmation.type === 'section') {
                response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/delete-section/${deleteConfirmation.id}`, {
                    method: 'DELETE',
                    headers: { 'X-CSRF-Token': csrfToken },
                    credentials: 'include',
                });
            } else if (deleteConfirmation.type === 'post') {
                response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/delete-post/${deleteConfirmation.sectionId}/${deleteConfirmation.postId}`, {
                    method: 'DELETE',
                    headers: { 'X-CSRF-Token': csrfToken },
                    credentials: 'include',
                });
            }

            if (!response.ok) {
                throw new Error(`Failed to delete ${deleteConfirmation.type}`);
            }

            setError(`${deleteConfirmation.type.charAt(0).toUpperCase() + deleteConfirmation.type.slice(1)} deleted successfully`);

            if (deleteConfirmation.type === 'section') {
                fetchSections(); // Refresh the sections list
            } else if (deleteConfirmation.type === 'post') {
                await fetchSectionPosts(deleteConfirmation.sectionId); // Refresh the posts for the specific section
            }
        } catch (error) {
            console.error(`Error deleting ${deleteConfirmation.type}:`, error);
            setError(`Failed to delete ${deleteConfirmation.type}. Please try again.`);
        } finally {
            setDeleteConfirmation(null);
        }
    };


    return (
        <Card className="w-full">
            <CardHeader>
                <CardTitle>SEO Content Management</CardTitle>
                <CardDescription>Generate and manage SEO content</CardDescription>
            </CardHeader>
            <CardContent>
                {error && <Alert variant={error.includes('Failed') ? "destructive" : "default"} className="mb-4"><AlertDescription>{error}</AlertDescription></Alert>}

                <div className="grid grid-cols-3 gap-4 mb-6">
                    {/* Automatic Mode */}
                    <Card className="col-span-1">
                        <CardContent className="pt-6">
                            <div className="flex items-center justify-between">
                                <Label htmlFor="automatic-mode" className="font-semibold">Automatic Mode</Label>
                                <Switch
                                    id="automatic-mode"
                                    checked={isAutomaticMode}
                                    onCheckedChange={handleToggleAutomaticMode}
                                    disabled={loadingStates.toggleMode}
                                />
                            </div>
                            <p className="text-sm text-muted-foreground mt-2">Automatic Posts (23:30 daily)</p>
                        </CardContent>
                    </Card>

                    {/* Create New Section */}
                    <Card className="col-span-1">
                        <CardContent className="pt-6">
                            <Label htmlFor="new-section" className="font-semibold">Create New Section</Label>
                            <div className="flex items-center space-x-2 mt-2">
                                <Input
                                    id="new-section"
                                    type="text"
                                    placeholder="Enter subject"
                                    value={inputSubject}
                                    onChange={(e) => setInputSubject(e.target.value)}
                                />
                                <Button
                                    onClick={handleCreateSection}
                                    disabled={loadingStates.createSection || !inputSubject.trim()}
                                >
                                    {loadingStates.createSection ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : <Plus className="mr-2 h-4 w-4" />}
                                    Create
                                </Button>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Generate All Sections */}
                    <Card className="col-span-1">
                        <CardContent className="pt-6">
                            <Label className="font-semibold">Generate All Sections</Label>
                            <Button
                                onClick={handleGenerateAllSpokeContent}
                                className="w-full mt-2"
                                disabled={loadingStates.generateAll}
                            >
                                {loadingStates.generateAll ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : null}
                                Generate for All Sections
                            </Button>
                        </CardContent>
                    </Card>
                </div>

                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Section</TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {sections.map((section) => (
                            <React.Fragment key={section.id}>
                                <TableRow>
                                    <TableCell>
                                        <Button
                                            variant="ghost"
                                            onClick={() => toggleSection(section.id)}
                                            className="w-full justify-start"
                                        >
                                            {expandedSections[section.id] ? <ChevronUp className="mr-2 h-4 w-4" /> : <ChevronDown className="mr-2 h-4 w-4" />}
                                            {section.title || `Section ${section.id}`}
                                        </Button>
                                    </TableCell>
                                    <TableCell>
                                        <div className="flex items-center space-x-2">
                                            <Button
                                                onClick={() => handleGenerateSpokeContentForSection(section.id)}
                                                disabled={loadingStates.generateSection[section.id]}
                                                className="flex items-center"
                                            >
                                                {loadingStates.generateSection[section.id] ? (
                                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                                ) : (
                                                    <Plus className="mr-2 h-4 w-4" />
                                                )}
                                                Generate Post
                                            </Button>
                                            <Button
                                                onClick={() => handleDeleteSection(section.id)}
                                                variant="destructive"
                                                className="flex items-center"
                                            >
                                                <Trash2 className="mr-2 h-4 w-4" />
                                                Delete Section
                                            </Button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                                {expandedSections[section.id] && sectionPosts[section.id] && (
                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            <Table>
                                                <TableHeader>
                                                    <TableRow>
                                                        <TableHead>Image</TableHead>
                                                        <TableHead>Title</TableHead>
                                                        <TableHead>Actions</TableHead>
                                                    </TableRow>
                                                </TableHeader>
                                                <TableBody>
                                                    {sectionPosts[section.id].map((post) => (
                                                        <TableRow key={post.id}>
                                                            <TableCell>
                                                                {post.featuredImage && post.featuredImage.url && post.featuredImage.url.trim() !== "" ? (
                                                                    <img src={post.featuredImage.url} alt={post.title} className="w-16 h-16 object-cover" />
                                                                ) : (
                                                                    <div className="w-16 h-16 bg-gray-200 flex items-center justify-center">No Image</div>
                                                                )}
                                                            </TableCell>
                                                            <TableCell>{post.title || 'Untitled'}</TableCell>
                                                            <TableCell>
                                                                <Button
                                                                    onClick={() => handleChangeImageForPost(section.id, post.id)}
                                                                    className="mr-2"
                                                                    disabled={loadingStates.changeImage[`${section.id}-${post.id}`]}
                                                                >
                                                                    {loadingStates.changeImage[`${section.id}-${post.id}`] ? (
                                                                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                                                    ) : null}
                                                                    Change Image
                                                                </Button>
                                                                <Button
                                                                    onClick={() => handleDeletePost(section.id, post.id)}
                                                                    variant="destructive"
                                                                >
                                                                    Delete
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
            <Dialog open={!!deleteConfirmation} onOpenChange={(open) => !open && setDeleteConfirmation(null)}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Confirm Deletion</DialogTitle>
                        <DialogDescription>
                            {deleteConfirmation?.message}
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter>
                        <Button variant="outline" onClick={() => setDeleteConfirmation(null)}>Cancel</Button>
                        <Button variant="destructive" onClick={confirmDelete}>OK</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </Card>
    );
};

export default SEO;