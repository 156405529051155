import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Calendar, User, ChevronDown, ChevronUp, ChevronRight, ArrowLeft, Shuffle } from "lucide-react";
import { useNavigate, useLocation } from "react-router-dom";
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "../ui/card";
import { Button } from "../ui/button";
import { Badge } from "../ui/badge";


const formatContent = (content) => {
    const paragraphs = content.split('\n\n');
    let inList = false;
    let listCounter = 1;
    return paragraphs.map((paragraph, index) => {
        if (paragraph.trim().endsWith(':')) {
            inList = false;
            listCounter = 1;
            return { type: 'heading', content: paragraph.trim() };
        }
        else if (/^\d+\./.test(paragraph)) {
            const lines = paragraph.split('\n');
            const formattedLines = lines.map(line => {
                if (/^\d+\./.test(line)) {
                    inList = true;
                    return `${listCounter++}. ${line.replace(/^\d+\./, '').trim()}`;
                } else {
                    return `   ${line.trim()}`;
                }
            });
            return { type: 'list', content: formattedLines.join('\n') };
        }
        else {
            inList = false;
            listCounter = 1;
            return { type: 'paragraph', content: paragraph.trim() };
        }
    });
};

const createUrlFriendlyTitle = (title) => {
    // Convert to lowercase
    let friendly = title.toLowerCase();

    // Remove less important words
    const wordsToRemove = [
        'a', 'an', 'the', 'in', 'on', 'at', 'to', 'for', 'of', 'with', 'and',
        'your', 'proven', 'key', 'powerful', 'yours', 'keys'
    ];
    friendly = friendly.split(' ')
        .filter(word => !wordsToRemove.includes(word))
        .join(' ');

    // Convert to URL-friendly format
    friendly = friendly.replace(/[^a-z0-9]+/g, '-')
        .replace(/(^-|-$)/g, '');

    // Limit the overall length (e.g., to 60 characters)
    friendly = friendly.slice(0, 60).replace(/-+$/, '');

    return friendly;
};

const TruncatedContent = ({ content, isFullContentVisible, toggleFullContent }) => {
    const formattedContent = formatContent(content);
    return (
        <div className="relative">
            <div className={`mt-4 mb-6 prose prose-sm dark:prose-invert text-xs leading-relaxed overflow-hidden ${!isFullContentVisible ? 'max-h-[150px]' : ''}`}>
                {formattedContent.map((item, index) => {
                    switch (item.type) {
                        case 'heading':
                            return <h2 key={index} className="text-lg font-semibold mt-4 mb-2">{item.content}</h2>;
                        case 'list':
                            return <div key={index} className="mb-4" dangerouslySetInnerHTML={{ __html: item.content.replace(/\n/g, '<br>') }} />;
                        case 'paragraph':
                        default:
                            return <p key={index} className="mb-4">{item.content}</p>;
                    }
                })}
            </div>
            {!isFullContentVisible && (
                <>
                    <div className="absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-black dark:from-black to-transparent"></div>
                    <div className="absolute bottom-0 left-0 right-0 flex justify-center">
                        <Button onClick={toggleFullContent} className="z-10 text-xs" variant="outline">
                            Keep Reading
                        </Button>
                    </div>
                </>
            )}
            {isFullContentVisible && (
                <div className="mt-4 flex justify-center">
                    <Button onClick={toggleFullContent} className="text-xs" variant="outline">
                        Show Less
                    </Button>
                </div>
            )}
        </div>
    );
};


const Breadcrumbs = ({ section, title }) => {
    return (
        <nav aria-label="Breadcrumb" className="text-xs text-gray-500 my-4">
            <ol className="list-none p-0 inline-flex">
                <li className="flex items-center">
                    <Link to="/" className="hover:text-gray-700">Home</Link>
                    <ChevronRight className="h-3 w-3 mx-1" />
                </li>
                {section !== "Blog" && (
                    <li className="flex items-center">
                        <Link to="/blog" className="hover:text-gray-700">Blog</Link>
                        <ChevronRight className="h-3 w-3 mx-1" />
                    </li>
                )}
                {section !== "Blog" && (
                    <li className="flex items-center">
                        <Link to={`/blog/${createUrlFriendlyTitle(section)}`} className="hover:text-gray-700">{section}</Link>
                        <ChevronRight className="h-3 w-3 mx-1" />
                    </li>
                )}
                <li className="text-gray-700">{title}</li>
            </ol>
        </nav>
    );
};


const addLinksToContent = (formattedContent, allPosts, currentPostTitle) => {
    const keywordMap = new Map();

    // Create a map of keywords to posts
    allPosts.forEach(post => {
        if (post.title !== currentPostTitle) {
            post.seoKeywords.forEach(keyword => {
                if (!keywordMap.has(keyword)) {
                    keywordMap.set(keyword, post);
                }
            });
        }
    });

    // Sort keywords by length (longest first) to prioritize longer phrases
    const sortedKeywords = Array.from(keywordMap.keys()).sort((a, b) => b.length - a.length);

    return formattedContent.map(item => {
        if (item.type === 'heading') {
            // Don't add links to headings
            return item;
        } else {
            let content = item.content;
            sortedKeywords.forEach(keyword => {
                const regex = new RegExp(`\\b${keyword}\\b`, 'gi');
                const post = keywordMap.get(keyword);
                content = content.replace(regex, (match) => {
                    return `__LINK_START__${createUrlFriendlyTitle(post.section)}/${createUrlFriendlyTitle(post.title)}__LINK_MID__${match}__LINK_END__`;
                });
            });
            return { ...item, content };
        }
    });
};

const ContentWithLinks = ({ content }) => {
    const parts = content.split(/__LINK_START__|__LINK_MID__|__LINK_END__/);
    return (
        <>
            {parts.map((part, index) => {
                if (index % 3 === 1) {
                    // This is a link URL
                    return null;
                } else if (index % 3 === 2) {
                    // This is link text
                    const linkUrl = parts[index - 1];
                    return (
                        <Link
                            key={index}
                            to={`/blog/${linkUrl}`}
                            className="text-gray-400 hover:underline"
                            onClick={(e) => e.stopPropagation()}
                        >
                            {part}
                        </Link>
                    );
                } else {
                    // This is regular text
                    return part;
                }
            })}
        </>
    );
};

const scrollToPost = (postId) => {
    setTimeout(() => {
        const postElement = document.getElementById(postId);
        if (postElement) {
            postElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, 100);
};


const BlogPost = ({ title, date, author, excerpt, content, isExpanded, onToggle, section, seoTitle, seoDescription, seoKeywords, featuredImage, urlFriendlyTitle, blogData }) => {
    const urlFriendlySection = createUrlFriendlyTitle(section);

    const handleCardClick = (e) => {
        if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'A') {
            onToggle(urlFriendlySection, urlFriendlyTitle);
        }
    };

    const formattedContent = formatContent(content);

    const linkedContent = addLinksToContent(formattedContent, blogData.blogPosts, title);

  

    const relatedPosts = blogData.blogPosts
        .filter(post => post.section === section && post.title !== title)
        .slice(0, 3);

    

    return (
        <Card
            id={`post-${urlFriendlyTitle}`}
            className={`${isExpanded ? 'col-span-full' : ''} cursor-pointer border border-gray-300 overflow-hidden`}
            onClick={(e) => {
                if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'A') {
                    onToggle(urlFriendlySection, urlFriendlyTitle);
                }
            }}
        >
            {featuredImage && featuredImage.url && (
                <div className="w-full h-48 overflow-hidden">
                    <img
                        src={featuredImage.url}
                        alt={featuredImage.alt || title}
                        className="w-full h-full object-cover"
                    />
                </div>
            )}
            <CardHeader>
                {isExpanded ? (
                    <h1 className="text-xl font-bold">{title}</h1>
                ) : (
                    <CardTitle className="text-xl font-bold">{title}</CardTitle>
                )}
                <div className="flex items-center space-x-2 text-xs">
                    <Badge variant="outline" className="flex items-center">
                        <Calendar className="w-3 h-3 mr-1" />{date}
                    </Badge>
                    <Badge variant="outline" className="flex items-center">
                        <User className="w-3 h-3 mr-1" />{author}
                    </Badge>
                </div>
                {!isExpanded && (
                    <CardDescription className="text-sm">{excerpt.slice(0, 150) + '...'}</CardDescription>
                )}
            </CardHeader>
            {isExpanded && (
                <CardContent>
                    <div className="prose prose-sm dark:prose-invert text-xs leading-relaxed">
                        {linkedContent.map((item, index) => {
                            switch (item.type) {
                                case 'heading':
                                    return <h2 key={index} className="text-lg font-semibold mt-4 mb-2">{item.content}</h2>;
                                case 'list':
                                    return <div key={index} className="mb-4">{item.content.split('\n').map((line, i) => <p key={i}><ContentWithLinks content={line} /></p>)}</div>;
                                case 'paragraph':
                                default:
                                    return <p key={index} className="mb-4"><ContentWithLinks content={item.content} /></p>;
                            }
                        })}
                    </div>
                </CardContent>
            )}
            <CardFooter className="flex flex-col items-start w-full">
                <div className="w-full flex justify-between items-start">
                    <Button
                        variant="outline"
                        onClick={(e) => {
                            e.stopPropagation();
                            onToggle(urlFriendlySection, urlFriendlyTitle);
                        }}
                    >
                        {isExpanded ? 'Close' : 'Read More'}
                    </Button>

                    {isExpanded && relatedPosts.length > 0 && (
                        <div className="ml-4">
                            <h3 className="text-sm font-semibold mb-2">Related posts:</h3>
                            <ul className="list-disc list-inside">
                                {relatedPosts.map((post, index) => (
                                    <li key={index} className="text-xs mb-1">
                                        <Link
                                            to={`/blog/${urlFriendlySection}/${createUrlFriendlyTitle(post.title)}`}
                                            className="text-blue-500 hover:underline"
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            {post.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </CardFooter>
        </Card>
    );
};

export default function Blog() {
    const [blogData, setBlogData] = useState({ blogSections: [], blogPosts: [] });
    const [expandedPost, setExpandedPost] = useState(null);
    const [expandedSection, setExpandedSection] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [fullContentVisible, setFullContentVisible] = useState({});
    const navigate = useNavigate();
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 6;

    useEffect(() => {
        const fetchBlogData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/blog-data`);
                if (!response.ok) {
                    const text = await response.text();
                    throw new Error(`Network response was not ok: ${text}`);
                }
                const data = await response.json();
                console.log('Fetched blog data:', data);
                setBlogData(data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching blog data:', error);
                setError(error.message);
                setIsLoading(false);
            }
        };

        fetchBlogData();
    }, []);

    const currentPost = blogData.blogPosts.find(post => createUrlFriendlyTitle(post.title) === expandedPost);

    useEffect(() => {
        const path = location.pathname.split('/blog/')[1];
        if (path) {
            const pathParts = path.split('/');
            if (pathParts.length === 1) {
                const section = blogData.blogSections.find(s => createUrlFriendlyTitle(s.title) === pathParts[0]);
                if (section) {
                    setExpandedSection(section.title);
                    setExpandedPost(null);
                    scrollToPost(`section-${createUrlFriendlyTitle(section.title)}`);
                }
            } else if (pathParts.length === 2) {
                const post = blogData.blogPosts.find(p =>
                    createUrlFriendlyTitle(p.section) === pathParts[0] &&
                    createUrlFriendlyTitle(p.title) === pathParts[1]
                );
                if (post) {
                    setExpandedSection(post.section);
                    setExpandedPost(createUrlFriendlyTitle(post.title));
                    scrollToPost(`post-${createUrlFriendlyTitle(post.title)}`);
                }
            }
        } else {
            setExpandedPost(null);
            setExpandedSection(null);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [location, blogData]);

    const handleToggle = (urlFriendlySection, urlFriendlyTitle) => {
        console.log('handleToggle called:', { urlFriendlySection, urlFriendlyTitle, currentExpandedPost: expandedPost });
        if (expandedPost === urlFriendlyTitle) {
            setExpandedPost(null);
            navigate(`/blog/${urlFriendlySection}`, { replace: true });
        } else {
            setExpandedPost(urlFriendlyTitle);
            navigate(`/blog/${urlFriendlySection}/${urlFriendlyTitle}`, { replace: true });
            scrollToPost(`post-${urlFriendlyTitle}`);
        }
    };

    const handleSectionToggle = (sectionTitle) => {
        const urlFriendlySection = createUrlFriendlyTitle(sectionTitle);
        if (expandedSection === sectionTitle) {
            setExpandedSection(null);
            setFullContentVisible(prev => ({ ...prev, [sectionTitle]: false }));
            navigate('/blog', { replace: true });
        } else {
            setExpandedSection(sectionTitle);
            setExpandedPost(null);
            setCurrentPage(prev => ({ ...prev, [sectionTitle]: 1 }));
            navigate(`/blog/${urlFriendlySection}`, { replace: true });
        }
    };

    const paginate = (sectionTitle, pageNumber) => {
        setCurrentPage(prev => ({ ...prev, [sectionTitle]: pageNumber }));
    };

    const toggleFullContent = (sectionTitle) => {
        setFullContentVisible(prev => ({ ...prev, [sectionTitle]: !prev[sectionTitle] }));
    };

    if (isLoading) {
        return <div>...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }





    return (
        <div className="container mx-auto px-4 py-16">
            {currentPost ? (
                <Helmet>
                    <title>{`${currentPost.seoTitle} | SaaS Apps`}</title>
                    <meta name="description" content={currentPost.seoDescription} />
                    <meta property="og:title" content={currentPost.seoTitle} />
                    <meta property="og:description" content={currentPost.seoDescription} />
                    <meta name="author" content={currentPost.author} />
                    <meta name="keywords" content={currentPost.seoKeywords.join(', ')} />
                </Helmet>
            ) : (
                    <Helmet>
                        <title>Frontier Codes Blog | Technology of Tomorrow</title>
                        <meta name="description" content="Explore the future of technology with Frontier Codes Blog. Dive into cutting-edge AI, software development trends, and innovative solutions shaping the digital landscape of tomorrow." />
                        <meta name="keywords" content="Frontier Codes, technology blog, AI, future tech, software development, innovation, machine learning, blockchain, IoT, cloud computing" />
                        <meta property="og:title" content="Frontier Codes Blog | Technology of Tomorrow" />
                        <meta property="og:description" content="Discover tomorrow's technology today. Join us as we explore groundbreaking innovations, emerging trends, and the future of tech on the Frontier Codes Blog." />                   
                        <meta property="og:type" content="website" />
                        <meta property="og:url" content="https://blog.frontier.codes/" />
                        <link rel="canonical" href="https://frontier.codes/blog" />
                    </Helmet>
            )}

            <div className="max-w-7xl mx-auto">
                <div className="flex justify-between items-center mb-8">
                    {!expandedPost && !expandedSection ? (
                        <h1 className="text-3xl font-bold text-left">Frontier Codes Blog</h1>
                    ) : (
                            <span className="text-3xl font-bold text-left">Frontier Codes Blog</span>
                    )}
                    <Button asChild className="bg-white text-black py-2 px-6 rounded-full text-sm hover:bg-opacity-90 transition duration-300 shadow-sm font-bold flex items-center space-x-2">
                        <Link to="/">Back</Link>
                    </Button>
                </div>


              
                {/* Main blog description */}
                {!expandedPost && !expandedSection && (
                    <Card className="mb-4">
                        <CardHeader className="pb-2">
                            <CardTitle className="text-lg font-semibold">
                                Efficient Software Development at Frontier Codes
                            </CardTitle>
                        </CardHeader>
                        <CardContent className="pt-0">
                            <div className="text-sm prose dark:prose-invert max-w-none leading-relaxed">
                                <p>
                                    At Frontier Codes, we leverage AI to enhance our development process for two key reasons:
                                </p>
                                <ul className="list-disc pl-5 mb-2">
                                    <li><strong>Reduced Time:</strong> AI helps us accelerate various stages of development.</li>
                                    <li><strong>Reduced Cost:</strong> By optimizing our processes, AI contributes to overall cost reduction.</li>
                                </ul>

                                <p>
                                    Our project lifecycle follows these key steps:
                                </p>
                                <ol className="list-decimal pl-5 mb-2">
                                    <li><strong>Initial Consultation:</strong> We start by understanding the customer's idea and vision.</li>
                                    <li><strong>Technology Selection:</strong> We choose the right technologies based on project requirements.</li>
                                    <li><strong>Project Planning:</strong> A detailed plan is created, outlining milestones and resources.</li>
                                    <li><strong>Demo Development:</strong> We create an initial demo to visualize the concept.</li>
                                    <li><strong>Iterative Development:</strong> The main development phase with regular client feedback.</li>
                                    <li><strong>Testing and Quality Assurance:</strong> Rigorous testing to ensure product quality.</li>
                                    <li><strong>Deployment:</strong> Smooth launch of the final product.</li>
                                    <li><strong>Post-Launch Support:</strong> Ongoing maintenance and updates as needed.</li>
                                </ol>

                                <p>
                                    Our tech stack is carefully chosen to best serve each unique project:
                                </p>
                                <ul className="list-disc pl-5 mb-2">
                                    <li><strong>Frontend:</strong> React, Vue.js for responsive and efficient user interfaces</li>
                                    <li><strong>Backend:</strong> Node.js, Python for scalable server-side applications</li>
                                    <li><strong>Database:</strong> PostgreSQL, MongoDB for reliable data management</li>
                                    <li><strong>Cloud & DevOps:</strong> AWS, Docker for flexible deployment and scaling</li>
                                </ul>
                            </div>
                            <Button
                                onClick={() => {
                                    const randomPost = blogData.blogPosts[Math.floor(Math.random() * blogData.blogPosts.length)];
                                    const urlFriendlySection = createUrlFriendlyTitle(randomPost.section);
                                    const urlFriendlyTitle = createUrlFriendlyTitle(randomPost.title);
                                    navigate(`/blog/${urlFriendlySection}/${urlFriendlyTitle}`);
                                }}
                                className="bg-white text-black py-2 px-6 rounded-full text-sm hover:bg-opacity-90 transition duration-300 shadow-sm font-bold flex items-center space-x-2 mt-4"
                            >
                                <Shuffle className="w-4 h-4 mr-2" />
                                Read a Random Post
                            </Button>
                        </CardContent>
                    </Card>
                )}


                {/* Add Breadcrumbs here */}
                <Breadcrumbs
                    section={expandedSection || "Blog"}
                    title={currentPost ? currentPost.title : "All Sections"}
                />

                {blogData.blogSections.map((section, index) => {
                    const sectionPosts = blogData.blogPosts.filter(post => post.section === section.title);
                    const totalPosts = sectionPosts.length;
                    const needsPagination = totalPosts > postsPerPage;

                    const indexOfLastPost = needsPagination
                        ? (currentPage[section.title] || 1) * postsPerPage
                        : totalPosts;
                    const indexOfFirstPost = needsPagination
                        ? indexOfLastPost - postsPerPage
                        : 0;

                    const currentPosts = sectionPosts.slice(indexOfFirstPost, indexOfLastPost);

                    return (
                        <div key={index} className="mb-8">
                            <Button
                                variant="outline"
                                className="w-full text-left justify-between items-center flex py-3 px-4 bg-gradient-to-r from-white/10 via-white/10 to-transparent backdrop-blur-md"
                                onClick={() => handleSectionToggle(section.title)}
                            >
                                {expandedSection === section.title && !expandedPost ? (
                                    <h1 className="font-semibold truncate mr-2 flex-grow">{section.title}</h1>
                                ) : expandedPost && blogData.blogPosts.find(post => createUrlFriendlyTitle(post.title) === expandedPost)?.section === section.title ? (
                                    <h4 className="font-semibold truncate mr-2 flex-grow">{section.title}</h4>
                                ) : (
                                    <h2 className="font-semibold truncate mr-2 flex-grow">{section.title}</h2>
                                )}
                                <span className="flex-shrink-0">
                                    {expandedSection === section.title ? (
                                        <ChevronUp className="w-5 h-5" />
                                    ) : (
                                        <ChevronDown className="w-5 h-5" />
                                    )}
                                </span>
                            </Button>
                            {expandedSection === section.title && (
                                <>
                                    <div className="relative">
                                        <div className={`mt-4 mb-6 prose prose-sm dark:prose-invert text-xs leading-relaxed overflow-hidden ${!fullContentVisible[section.title] ? 'max-h-[150px]' : ''}`}>
                                            {formatContent(section.content).map((item, itemIndex) => {
                                                switch (item.type) {
                                                    case 'heading':
                                                        return expandedPost ? (
                                                            <h5 key={itemIndex} className="text-lg font-semibold mt-4 mb-2">{item.content}</h5>
                                                        ) : (
                                                            <h2 key={itemIndex} className="text-lg font-semibold mt-4 mb-2">{item.content}</h2>
                                                        );
                                                    case 'list':
                                                        return <div key={itemIndex} className="mb-4" dangerouslySetInnerHTML={{ __html: item.content.replace(/\n/g, '<br>') }} />;
                                                    case 'paragraph':
                                                    default:
                                                        return <p key={itemIndex} className="mb-4">{item.content}</p>;
                                                }
                                            })}
                                        </div>
                                        {!fullContentVisible[section.title] && (
                                            <>
                                                <div className="absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-black dark:from-black to-transparent"></div>
                                                <div className="absolute bottom-0 left-0 right-0 flex justify-center">
                                                    <Button onClick={() => toggleFullContent(section.title)} className="z-10 text-xs" variant="outline">
                                                        Keep Reading
                                                    </Button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {fullContentVisible[section.title] && (
                                        <>
                                            {section.featuredImage && section.featuredImage.url && (
                                                <div className="mt-6 relative">
                                                    <div className="relative h-48 overflow-hidden">
                                                        <img
                                                            src={section.featuredImage.url}
                                                            alt={section.featuredImage.alt || section.title}
                                                            className="w-full h-full object-cover rounded-lg"
                                                        />
                                                        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                                                        <div className="absolute inset-0 flex items-center justify-center">
                                                            <Button onClick={() => toggleFullContent(section.title)} className="text-xs z-10" variant="outline">
                                                                Show Less
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-4">
                                        {currentPosts.map((post, postIndex) => (
                                            <BlogPost
                                                key={postIndex}
                                                {...post}
                                                isExpanded={expandedPost === createUrlFriendlyTitle(post.title)}
                                                onToggle={handleToggle}
                                                blogData={blogData}
                                                urlFriendlyTitle={createUrlFriendlyTitle(post.title)}
                                            />
                                        ))}
                                    </div>

                                    {needsPagination && (
                                        <Pagination
                                            postsPerPage={postsPerPage}
                                            totalPosts={totalPosts}
                                            paginate={(pageNumber) => paginate(section.title, pageNumber)}
                                            currentPage={currentPage[section.title] || 1}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}



const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <nav className="mt-8">
            <ul className="flex justify-center space-x-2">
                {pageNumbers.map(number => (
                    <li key={number}>
                        <Button
                            onClick={() => paginate(number)}
                            variant={currentPage === number ? "default" : "outline"}
                            className="px-3 py-1"
                        >
                            {number}
                        </Button>
                    </li>
                ))}
            </ul>
        </nav>
    );
};