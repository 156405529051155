import React, { useEffect, useRef } from 'react';

const LiquidMetalBackground = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const gl = canvas.getContext('webgl');

        if (!gl) {
            console.error('WebGL not supported');
            return;
        }

        const vertexShader = `
      attribute vec4 a_position;
      varying vec2 v_uv;
      void main() {
        v_uv = a_position.xy * 0.5 + 0.5;
        gl_Position = a_position;
      }
    `;

        const fragmentShader = `
      precision highp float;
      varying vec2 v_uv;
      uniform vec2 u_resolution;
      uniform float u_time;

      float cheapNoise(vec3 stp) {
        vec3 p = vec3(stp.st, stp.p);
        vec4 a = vec4(5.0, 7.0, 9.0, 13.0);
        return mix(
          sin(p.z + p.x * a.x + cos(p.x * a.x - p.z)) * 
          cos(p.z + p.y * a.y + cos(p.y * a.x + p.z)),
          sin(1.0 + p.x * a.z + p.z + cos(p.y * a.w - p.z)) * 
          cos(1.0 + p.y * a.w + p.z + cos(p.x * a.x + p.z)), 
          0.436
        );
      }

      void main() {
        vec2 uv = v_uv;
        vec2 aR = vec2(u_resolution.x / u_resolution.y, 1.0);
        vec2 st = uv * aR * 0.4;
        float S = sin(u_time * 0.005);
        float C = cos(u_time * 0.005);
        vec2 v1 = vec2(cheapNoise(vec3(st, 2.0)), cheapNoise(vec3(st, 1.0)));
        vec2 v2 = vec2(
          cheapNoise(vec3(st + v1 + vec2(C * 1.7, S * 9.2), 0.15 * u_time)),
          cheapNoise(vec3(st + v1 + vec2(S * 8.3, C * 2.8), 0.126 * u_time))
        );
        float n = 0.5 + 0.5 * cheapNoise(vec3(st + v2, 0.0));
        
        vec3 color1 = vec3(1.0, 1.0, 1.0);     // White
        vec3 color2 = vec3(0.2, 0.2, 0.2);     // Dark gray 
        vec3 color3 = vec3(0.2, 0.2, 0.2);     // Dark gray 
        vec3 color4 = vec3(0.0, 0.0, 0.0);     // Black

        vec3 color = mix(color1, color2, clamp((n*n)*8.0, 0.0, 1.0));
        color = mix(color, color3, clamp(length(v1), 0.0, 1.0));
        color = mix(color, color4, clamp(length(v2.x), 0.0, 1.0));
        
        color /= n*n + n * 7.0;
        
        // Slightly darken the effect
        color = pow(color, vec3(1.2));
        color = mix(color, vec3(0.0), 0.2);

        gl_FragColor = vec4(color, 1.0);
      }
    `;

        // Compile shaders
        const vs = gl.createShader(gl.VERTEX_SHADER);
        gl.shaderSource(vs, vertexShader);
        gl.compileShader(vs);

        const fs = gl.createShader(gl.FRAGMENT_SHADER);
        gl.shaderSource(fs, fragmentShader);
        gl.compileShader(fs);

        // Create program
        const program = gl.createProgram();
        gl.attachShader(program, vs);
        gl.attachShader(program, fs);
        gl.linkProgram(program);

        // Set up buffers
        const positionBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([-1, -1, 1, -1, -1, 1, 1, 1]), gl.STATIC_DRAW);

        // Set up attributes and uniforms
        const positionLocation = gl.getAttribLocation(program, 'a_position');
        const resolutionLocation = gl.getUniformLocation(program, 'u_resolution');
        const timeLocation = gl.getUniformLocation(program, 'u_time');

        gl.useProgram(program);
        gl.enableVertexAttribArray(positionLocation);
        gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);

        // Animation loop
        let startTime = Date.now();
        function render() {
            // Set canvas size
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            gl.viewport(0, 0, canvas.width, canvas.height);

            // Set uniforms
            gl.uniform2f(resolutionLocation, canvas.width, canvas.height);
            gl.uniform1f(timeLocation, (Date.now() - startTime) * 0.001);

            // Draw
            gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);

            requestAnimationFrame(render);
        }

        render();

        // Cleanup
        return () => {
            gl.deleteProgram(program);
            gl.deleteShader(vs);
            gl.deleteShader(fs);
            gl.deleteBuffer(positionBuffer);
        };
    }, []);

    return <canvas ref={canvasRef} className="absolute inset-0 w-full h-full" />;
};

export default LiquidMetalBackground;