import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { TooltipProvider } from './components/ui/tooltip';
import Homepage from './components/Homepage';



import Blog from './components/pages/BlogAI';


import LoginAdmin from './components/Login-admin';
import AdminDashboard from './components/Dashboard-admin';
import SEO from './components/sections-admin/SEO';
import Logs from './components/sections-admin/Logs';

import './index.css';
import FacebookPixel from './components/tracking/FacebookPixel';
import GoogleAnalytics from './components/tracking/GoogleAnalytics';


function App() {
    useEffect(() => {
        document.documentElement.classList.add('black');
    }, []);

    return (
        <Router>
            <FacebookPixel pixelId={process.env.REACT_APP_FACEBOOK_PIXEL_ID} />
            <GoogleAnalytics measurementId={process.env.REACT_APP_GA_MEASUREMENT_ID} />
            <TooltipProvider>
                <Routes>
                    <Route path="/" element={<Homepage />} />
                  
                    <Route path="/blog/*" element={<Blog />} />

                    {/* Admin routes */}
                    <Route path="/awh" element={<LoginAdmin />} />
                    <Route path="/awh/dashboard/*" element={<AdminDashboard />}>
                        <Route index element={<Navigate to="seo" replace />} />
                        <Route path="seo" element={<SEO />} />
                        <Route path="logs" element={<Logs />} />
                      
                        {/* Add more admin routes here */}
                    </Route>

                  
                   
                </Routes>
            </TooltipProvider>
        </Router>
    );
}

export default App;