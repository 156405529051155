import React from 'react';
import { Helmet } from 'react-helmet';
import LiquidMetalBackground from './sections-main/LiquidMetalBackground';
import Header from './sections-main/Header';
import HeroContent from './sections-main/HeroContent';
import TechnologySlider from './sections-main/TechnologySlider';
import Nav from './sections-main/Nav';
import How from './sections-main/How';
import Footer from './sections-main/Footer';
import '../App.css';
import '../index.css';

const Homepage = () => {
    return (
        <>
            <Helmet>
                <title>Frontier Codes | Faster. Cheaper. Better.</title>
                <meta name="description" content="Harnessing cutting-edge AI to transform software development. We deliver enterprise-grade solutions with unprecedented speed and cost-efficiency, without compromising on quality or innovation." />
                <meta name="keywords" content="Frontier Codes, AI software development, enterprise solutions, React, Node.js, Python, AWS, Docker" />
                <meta property="og:title" content="Frontier Codes | AI-Powered Software Development" />
                <meta property="og:description" content="Faster. Cheaper. Better. We deliver enterprise-grade solutions with 50% faster delivery and 40% cost reduction." />
               
                <meta property="og:url" content="https://frontier.codes" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Frontier Codes | AI-Powered Software Development" />
                <meta name="twitter:description" content="Faster. Cheaper. Better. 50% faster delivery, 40% cost reduction, 100% satisfaction." />
               
                <link rel="canonical" href="https://frontier.codes" />
            </Helmet>
            <div className="relative min-h-screen w-full overflow-hidden">
                <LiquidMetalBackground className="absolute inset-0 w-full h-full" />
                <div className="relative z-10 min-h-screen flex flex-col">
                    <Header className="p-8" />
                    <main className="flex-grow overflow-y-auto">
                        <div className="min-h-full flex flex-col justify-between p-8">
                            <div className="flex-grow flex flex-col justify-center space-y-12 mt-20">
                                <HeroContent />
                               
                                <TechnologySlider />
                                <How />
                                <Nav />
                            </div>
                        </div>
                    </main>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default Homepage;