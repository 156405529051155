import React, { useRef, useEffect, useState, useCallback } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Lightbulb, FileSpreadsheet, Cpu, PenTool, TestTube, Rocket, ArrowRight } from 'lucide-react';

const blurIn = keyframes`
  0% { filter: blur(10px); opacity: 0; transform: translateY(20px); }
  100% { filter: blur(0); opacity: 1; transform: translateY(0); }
`;

const fallOut = keyframes`
  0% { transform: translateY(0) rotate(0); opacity: 1; }
  100% { transform: translateY(100px) rotate(360deg); opacity: 0; }
`;

const AnimatedDiv = styled.div`
  opacity: 0;
  ${({ isVisible }) => isVisible && css`
    animation: ${blurIn} 1s ease-out forwards;
  `}
`;

const FallingLetter = styled.span`
  display: inline-block;
  animation: ${fallOut} 0.5s forwards;
  animation-delay: ${props => props.delay}s;
`;

const processSteps = [
    { icon: Lightbulb, title: "Idea", description: "Translate vision into actionable plan" },
    { icon: Cpu, title: "Tech Stack", description: "Select cost-efficient, scalable technologies" },
    { icon: FileSpreadsheet, title: "Planning", description: "Develop comprehensive project roadmap" },
    { icon: PenTool, title: "Demo", description: "Create functional prototype to meet requirements" },
    { icon: TestTube, title: "Testing", description: "Rigorous quality assurance and bugs fixing" },
    { icon: Rocket, title: "Launch", description: "Go live with the app and deploy" },
];

const ProcessCard = ({ icon: Icon, title, description, width, isVisible }) => {
    const [isFalling, setIsFalling] = useState(false);

    const handleClick = useCallback(() => {
        if (!isFalling) {
            setIsFalling(true);
            setTimeout(() => setIsFalling(false), 3000);
        }
    }, [isFalling]);

    const renderText = (text) => {
        if (!isFalling) return text;
        return text.split('').map((char, index) => (
            <FallingLetter key={index} delay={Math.random() * 0.5}>
                {char}
            </FallingLetter>
        ));
    };

    return (
        <AnimatedDiv isVisible={isVisible} style={{ width }}>
            <div
                className="bg-black bg-opacity-100 backdrop-blur-sm rounded-xl p-4 flex flex-col items-center text-center border border-gray-600 transition-transform hover:scale-105 h-full hover:border-gray-600 cursor-pointer overflow-hidden"
                onClick={handleClick}
            >
                <Icon size={32} className="text-white mb-2" />
                <h3 className="text-lg font-bold text-white mb-1">{renderText(title)}</h3>
                <p className="text-sm text-gray-300">{renderText(description)}</p>
            </div>
        </AnimatedDiv>
    );
};

const ArrowConnector = styled.div`
  display: flex;
  justify-center: center;
  align-items: center;
  padding: 0.5rem;
  @media (max-width: 768px) {
    transform: rotate(90deg);
    padding: 1rem 0;
  }
`;

const How = () => {
    const [cardWidth, setCardWidth] = useState(0);
    const [visibleCards, setVisibleCards] = useState([]);
    const cardRefs = useRef([]);

    useEffect(() => {
        const maxWidth = Math.max(...cardRefs.current.map(ref => ref.offsetWidth));
        setCardWidth(maxWidth);

        processSteps.forEach((_, index) => {
            setTimeout(() => {
                setVisibleCards(prev => [...prev, index]);
            }, index * 300);
        });
    }, []);

    return (
        <div className="relative py-4 px-4">
            <AnimatedDiv className="container mx-auto" isVisible={true}>
                <h2 className="text-2xl font-extrabold text-white text-center mb-4">
                    How we do it?
                </h2>
                <div className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-2">
                    {processSteps.map((step, index) => (
                        <React.Fragment key={index}>
                            <div ref={el => cardRefs.current[index] = el}>
                                <ProcessCard
                                    {...step}
                                    width={cardWidth ? `${cardWidth}px` : 'auto'}
                                    isVisible={visibleCards.includes(index)}
                                />
                            </div>
                            {index < processSteps.length - 1 && (
                                <ArrowConnector>
                                    <ArrowRight className="text-white opacity-50" size={24} />
                                </ArrowConnector>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </AnimatedDiv>
        </div>
    );
};

export default How;