import React from 'react';
import { Mail } from 'lucide-react';

// Function to combine email parts
const combineEmail = () => {
    const username = 'hello';
    const domain = 'frontier.codes';
    return `${username}@${domain}`;
};

// Function to handle email click
const handleEmailClick = () => {
    const email = combineEmail();
    window.location.href = `mailto:${email}`;
};

const Header = () => (
    <header className="absolute top-0 left-0 right-0 z-20 p-4">
        <nav className="flex justify-between items-center">
            <h1 className="text-xl font-bold font-mono">
                <span className="text-gray-400">&lt;</span>
                <span className="text-white">frontier</span>
                <span className="text-gray-400">.</span>
                <span className="text-white">codes</span>
                <span className="text-gray-400">/&gt;</span>
            </h1>
            <button
                onClick={handleEmailClick}
                className="bg-white text-black py-2 px-6 rounded-full text-sm hover:bg-opacity-90 transition duration-300 shadow-sm font-bold flex items-center space-x-2"
            >
                <Mail size={14} />
                <span>Contact</span>
            </button>
        </nav>
    </header>
);

export default Header;