import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from "../ui/card";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Alert, AlertDescription } from "../ui/alert";

const PortfolioEditor = () => {
    const [portfolioItems, setPortfolioItems] = useState([]);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [csrfToken, setCsrfToken] = useState('');

    useEffect(() => {
        fetchCsrfToken();
        fetchPortfolioItems();
    }, []);

    const fetchCsrfToken = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/csrf-token`, {
                method: 'GET',
                credentials: 'include',
            });
            if (response.ok) {
                const data = await response.json();
                setCsrfToken(data.csrfToken);
            } else {
                throw new Error('Failed to fetch CSRF token');
            }
        } catch (error) {
            console.error('Error fetching CSRF token:', error);
        }
    };

    const fetchPortfolioItems = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/portfolio-items`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'X-CSRF-Token': csrfToken
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch portfolio items');
            }
            const data = await response.json();
            setPortfolioItems(data);
        } catch (error) {
            console.error('Error fetching portfolio items:', error);
            setError('Failed to fetch portfolio items. Please try again.');
        }
    };

    const handleInputChange = (index, field, value) => {
        const updatedItems = [...portfolioItems];
        updatedItems[index][field] = value;
        setPortfolioItems(updatedItems);
    };

    const handleAddItem = () => {
        setPortfolioItems([...portfolioItems, { name: '', url: '' }]);
    };

    const handleRemoveItem = (index) => {
        const updatedItems = portfolioItems.filter((_, i) => i !== index);
        setPortfolioItems(updatedItems);
    };

    const handleSubmit = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/update-portfolio`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': csrfToken
                },
                credentials: 'include',
                body: JSON.stringify({ portfolioItems }),
            });
            if (!response.ok) {
                throw new Error('Failed to update portfolio');
            }
            setSuccess('Portfolio updated successfully');
            setError('');
        } catch (error) {
            console.error('Error updating portfolio:', error);
            setError('Failed to update portfolio. Please try again.');
            setSuccess('');
        }
    };

    return (
        <Card>
            <CardHeader>
                <CardTitle>Edit Portfolio</CardTitle>
            </CardHeader>
            <CardContent>
                {error && <Alert variant="destructive" className="mb-4"><AlertDescription>{error}</AlertDescription></Alert>}
                {success && <Alert variant="success" className="mb-4"><AlertDescription>{success}</AlertDescription></Alert>}
                {portfolioItems.map((item, index) => (
                    <div key={index} className="mb-4 flex items-center space-x-2">
                        <Input
                            placeholder="Project Name"
                            value={item.name}
                            onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                        />
                        <Input
                            placeholder="Project URL"
                            value={item.url}
                            onChange={(e) => handleInputChange(index, 'url', e.target.value)}
                        />
                        <Button variant="destructive" onClick={() => handleRemoveItem(index)}>Remove</Button>
                    </div>
                ))}
                <Button onClick={handleAddItem} className="mr-2">Add Item</Button>
                <Button onClick={handleSubmit}>Save Changes</Button>
            </CardContent>
        </Card>
    );
};

export default PortfolioEditor;