import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faReact,
    faNodeJs,
    faJava,
    faDocker,
    faMicrosoft,
    faCloudflare,
    faStripe
} from '@fortawesome/free-brands-svg-icons';
import { faDatabase, faCode, faGlobe } from '@fortawesome/free-solid-svg-icons';

const TechnologySlider = () => {
    const containerRef = useRef(null);
    const [clones, setClones] = useState(1);
    const technologies = [
        { name: 'Node.js', icon: faNodeJs },
        { name: 'React', icon: faReact },
        { name: 'Next.js', icon: faCode },
        { name: 'Java', icon: faJava },
        { name: 'PostgreSQL', icon: faDatabase },
        { name: 'Azure', icon: faMicrosoft },
        { name: 'Docker', icon: faDocker },
        { name: 'Namecheap', icon: faGlobe },
        { name: 'Cloudflare', icon: faCloudflare },
        { name: 'Stripe', icon: faStripe },
    ];

    useEffect(() => {
        const adjustClones = () => {
            if (containerRef.current) {
                const containerWidth = containerRef.current.offsetWidth;
                const itemWidth = 150; // Fixed width for each item
                const itemsPerScreen = Math.ceil(containerWidth / itemWidth);
                const newClones = Math.ceil(itemsPerScreen / technologies.length) + 1;
                setClones(newClones);
            }
        };
        adjustClones();
        window.addEventListener('resize', adjustClones);
        return () => window.removeEventListener('resize', adjustClones);
    }, []);

    return (
        <div className="relative">
            <h2 className="text-2xl font-bold text-white mb-6 text-center">Technologies used to build your ideas</h2>
            <div className="bg-black bg-opacity-100 py-4 rounded-lg overflow-hidden border border-gray-600 relative">
                {/* Shadow overlays */}
                <div className="absolute inset-y-0 left-0 w-24 bg-gradient-to-r from-black via-black to-transparent z-10"></div>
                <div className="absolute inset-y-0 right-0 w-24 bg-gradient-to-l from-black via-black to-transparent z-10"></div>
                {/* Slider content */}
                <div
                    ref={containerRef}
                    className="relative w-full overflow-hidden"
                    style={{
                        '--item-count': technologies.length,
                        '--item-width': '150px'
                    }}
                >
                    <div className="flex animate-scroll">
                        {[...Array(clones)].flatMap((_, i) =>
                            technologies.map((tech, index) => (
                                <div
                                    key={`${i}-${index}`}
                                    className="flex-shrink-0 w-[150px] flex flex-col items-center justify-center text-center px-4"
                                >
                                    <FontAwesomeIcon icon={tech.icon} className="text-4xl text-white mb-2" />
                                    <p className="text-white text-sm whitespace-nowrap">{tech.name}</p>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TechnologySlider;